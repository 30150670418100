import React from 'react';
import ReactDOM from 'react-dom';
import '../config.js';
import './Home.css';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import logoIcon from '../Img/larin-icon.svg';
import HouseSingle from '../Main/HouseSingle';
import Explore from '../Main/Explore';
import axios from "axios";
import ReactGA from "react-ga4";
import Banner from '../Components/Banner';
import Footer from '../Components/Footer';
import $ from 'jquery';
import {
	Link
  } from "react-router-dom";
import hscroll1 from '../Img/hscroll-1.png';
import hscroll2 from '../Img/hscroll-2.png';
import hscroll3 from '../Img/hscroll-3.png';
import hscroll4 from '../Img/hscroll-4.png';
import hscroll5 from '../Img/hscroll-5.png';
import arrowNav from '../Img/arrow-navigation.svg';
import closeIcon from '../Img/close-icon.svg';

class Home extends React.Component {
	constructor(props){
    super(props);
		this.state = {
		   arrowRotation1: 'arrow-up',
		   arrowRotation2: 'arrow-down',
		   arrowRotation3: 'arrow-down',
		   banners: [],
		   spacesFeatured: [],
		   visibleSpacesFeatured: [],
		   districts: [],
		   district: [],
		   services: [],
		   blogs: [],
		   slides: [],
		   notes: [],
		   ownSpaceClose: false,
		   myDistrict: this.props.myDistrict,
		   scrollPos: 0,
		}
		this.rotateArrow = this.rotateArrow.bind(this);
	}
	
	expandSpacesFeatured = currentLength => {
		this.setState({ visibleSpacesFeatured: this.state.spacesFeatured.slice(0,currentLength+10) });
	};

	closeOwnSpace = () => {
		this.setState({ ownSpaceClose: true });
	};

	hscrollRight(){
		if((this.state.scrollPos + 300) > ($('.hscroll-container').prop('scrollWidth') - window.innerWidth)){
		  $('.hscroll-arrow-right').css('display','none');
		}
		$('.hscroll-container').animate({scrollLeft: this.state.scrollPos + 300}, 500);
		$('.hscroll-arrow-left').css('display','block');
		if((this.state.scrollPos + 300) > ($('.hscroll-container').prop('scrollWidth') - window.innerWidth)) this.setState({ scrollPos: ($('.hscroll-container').prop('scrollWidth') - window.innerWidth) });
		else this.setState({ scrollPos: this.state.scrollPos + 300 });
	  }
	
	  hscrollLeft(){
		if((this.state.scrollPos - 300) <= 0){
		  $('.hscroll-arrow-left').css('display','none');
		}
		$('.hscroll-container').animate({scrollLeft: this.state.scrollPos - 300}, 500);
		$('.hscroll-arrow-right').css('display','block');
		if((this.state.scrollPos - 300) <= 0) this.setState({ scrollPos: 0 });
		else this.setState({ scrollPos: this.state.scrollPos - 300 });
	  }
	
	rotateArrow(i){
		if(i==1){
			if(this.state.arrowRotation1 == 'arrow-down'){
				this.setState({
				  arrowRotation1: 'arrow-up',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
		if(i==2){
			if(this.state.arrowRotation2 == 'arrow-down'){
				this.setState({
				   arrowRotation1: 'arrow-down',
				   arrowRotation2: 'arrow-up',
				   arrowRotation3: 'arrow-down'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
		if(i==3){
			if(this.state.arrowRotation3 == 'arrow-down'){
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-up'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
	}
	componentDidMount(){
		ReactGA.send({ hitType: "pageview", page: window.location.pathname });
		// START: GET BANNERS
		axios.get(global.config.apiUrl+"getBanners")
		.then(res => {
			const banners = res.data;
			this.setState({ banners });
		  })
		// END: GET BANNERS
		// START: GET SLIDES
		axios.get(global.config.apiUrl+"getSlidesApp/")
		.then(res => {
			const slides = res.data;
			this.setState({ slides });
		  })
		// END: GET SLIDES
		// START: GET BLOG
		axios.get(global.config.apiUrl+"getBlog")
		.then(res => {
			const blogs = res.data;
			console.log(JSON.stringify(blogs));
			this.setState({ blogs });
		})
		// END: GET BLOG
		// START: GET SPACES FEATURED
		axios.get(global.config.apiUrl+"getSpacesByDistrict/"+this.state.myDistrict)
		.then(res => {
			const spacesFeatured = res.data;
			this.setState({ spacesFeatured });
			const visibleSpacesFeatured = spacesFeatured.slice(0, 10);
			this.setState({ visibleSpacesFeatured });
		  })
		// END: GET SPACES FEATURED
		// START: GET DISTRICT BY NAME
		axios.get(global.config.apiUrl+"getDistrictByName/"+this.state.myDistrict)
		.then(res => {
			const district = res.data;
			this.setState({ district });
		  })
		// END: GET DISTRICT BY NAME
		// START: GET DISTRICTS WITH SPACES
		axios.get(global.config.apiUrl+"getDistrictsWithSpaces")
		.then(res => {
			const districts = res.data;
			this.setState({ districts });
		  })
		// END: GET DISTRICTS WITH SPACES

		// START: GET SERVICES
		axios.get(global.config.apiUrl+"getServices/")
		.then(res => {
			const services = res.data;
			this.setState({ services });
		  })
		// END: GET SERVICES

		axios.get(global.config.apiUrl+"getConfig/")
		.then(res => {
			const notes = res.data;
			this.setState({ notes });
		  })
		// END: GET SERVICES
	}
	componentDidUpdate(prevProps){
		const { myDistrict } = this.props;
		if (myDistrict !== prevProps.myDistrict) {
			this.setState({ myDistrict });
			// START: GET SPACES FEATURED
			axios.get(global.config.apiUrl+"getSpacesByDistrict/"+myDistrict)
			.then(res => {
				const spacesFeatured = res.data;
				this.setState({ spacesFeatured });
				const visibleSpacesFeatured = this.state.spacesFeatured.slice(0, 10);
				this.setState({ visibleSpacesFeatured });
			})
			// END: GET SPACES FEATURED
			// START: GET DISTRICT BY NAME
			axios.get(global.config.apiUrl+"getDistrictByName/"+myDistrict)
			.then(res => {
				const district = res.data;
				this.setState({ district });
			})
			// END: GET DISTRICT BY NAME
		}
		
	}
	render () {
		const options = [
		  { value: 'pt', label: 'Português' },
		  { value: 'en', label: 'English' },
		  { value: 'en', label: 'Español' }
		]
		const { arrowRotation1, arrowRotation2, arrowRotation3, banners, spacesFeatured, districts, district, services, ownSpaceClose, blogs } = this.state;
		return(
			<div className="home main">
				<div className="carousel-container">
					{/*
					<Carousel controls={false} fade={true}>
						{banners.map((value, index) => {
							return (
								<Carousel.Item key={index}>
									<img
										className="d-block w-100"
										src={global.config.uploadsPath + value.FILE}
										alt="First slide"
									/>
									<Carousel.Caption>
										<h3>{value.TITLE1}</h3>
										<p>{value.SUBTITLE1}</p>
									</Carousel.Caption>
									<div className="info-bottom">
										<p className="title">{value.TITLE2}</p>
										<p className="description">{value.SUBTITLE2} <a href="#">Ver mais &gt;</a></p>
									</div>
								</Carousel.Item>
							)
						})}
					</Carousel>
					*/}
					<Banner />
					{/*
					<div className="search-container">
						<input type="text" className="search" placeholder='Experimente procurar "Porto"'/>
						<div className="search-icon">
							<i className="icon-search-icon"></i>
						</div>
					</div>
					*/}
				</div>
				<div className="container">
					<div className={"own-space hide-desktop" + (ownSpaceClose ? 'closed' : '')}>
						<div>
							<img
							src={process.env.PUBLIC_URL + '/Slides/slide-3.jpg'}
							alt="Third slide"
							/>
						</div>
						<div className="own-space-text">
							<h4>Saiba quanto poderia ganhar ao promover o seu espaço</h4>
							<p>Coloque o seu espaço disponível para todos que procuram um lar</p>
						</div>
						<button className="btn-close" onClick={() => this.closeOwnSpace()}><i className="menu-icon icon-close-btt"></i></button>
					</div>
					{/*<div className="features section">
						{district &&
							<div>
								<h3 className="title-default"><span className='highlight-title'>Melhores lares</span> e residências {district.PREFIX} {district.NAME}</h3>
								<p className="subtitle-default">Encontre o lar ou residência para proporcionar o maior conforto ao seu ente mais querido.</p>
							</div>
						}
						<Row className='hide-desktop'>
							{this.state.visibleSpacesFeatured.map((value, index) => {
								return (
									<Col xs={6} md={3}>
										<Link to={"/house/"+value.URL}>
											<img
											className="d-block w-100 house-img"
											src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
											alt="First slide"
											/>
											{district &&
												<p className="house-location">Matosinhos, {district.NAME}</p>
											}
											<p className="house-name">{value.NAME}</p>
											<p className="house-price">Desde {value.PRICE} €/ mês</p>
											<p className="house-rating"><FontAwesomeIcon icon={Icons.faStar} /> {value.RATING} <span>(888)</span></p>
										</Link>
									</Col>
								)
							})}
						</Row>
						<div className="show-desktop">
							{this.state.visibleSpacesFeatured.map((value, index) => {
								return (
									<div className="features-item">
										<Link to={"/house/"+value.URL}>
											<img
											className="d-block w-100 house-img"
											src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
											alt="First slide"
											/>
											{district &&
												<p className="house-location">Matosinhos, {district.NAME}</p>
											}
											<p className="house-name">{value.NAME}</p>
											<p className="house-price">Desde {value.PRICE} €/ mês</p>
											<p className="house-rating"><FontAwesomeIcon icon={Icons.faStar} /> {value.RATING} <span>(888)</span></p>
										</Link>
									</div>
								)
							})}
						</div>
						{this.state.visibleSpacesFeatured && this.state.visibleSpacesFeatured.length < spacesFeatured.length &&
							<a onClick={() => this.expandSpacesFeatured(this.state.visibleSpacesFeatured.length)} className="btn-view-more">Ver mais <FontAwesomeIcon icon={Icons.faArrowRight} /></a>
						}
					</div>*/}
					{/*
					<div className="explore-zone section">
						<h3 className="title-default">Explore por <span className='highlight-title'>zonas</span></h3>
						<p className="subtitle-default">Uma nova seleção de casas com conforto e qualidade verificados</p>
						<div className="zones-scroll">
							<div className="zones-container">
								{districts.map((value, index) => {
									return (
										<div className="zone-item">
											<Link to={"/explore/"+value.DISTRICT_ID+"/"+value.PREFIX+"/"+value.NAME+"/0"}>
												<div className="zone-img-container">
													<img
													className="d-block w-100 zone-img"
													src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
													alt="First slide"
													/>
													<p>Lares {value.PREFIX} {value.NAME}</p>
												</div>
												<p className="zone-district">Distrito {value.NAME}</p>
												<p className="zone-desc">Veja a oferta de lares, centros de…</p>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
					</div>
					*/}
					<div className="explore-zone section">
						<h3 className="title-default">Revista <span className='highlight-title'>Larin</span></h3>
						{/*<p className="subtitle-default">Uma nova seleção de casas com conforto e qualidade verificados</p>*/}
						<div className="zones-scroll">
							<div className="zones-container">
								{blogs.map((value, index) => {
									return (
										<div className="zone-item">
											<Link to={"/revistasingle/"+value.BLOG_ID+"/"+encodeURI(value.TITLE.replace(/ /g,"-"))}>
												<div className="zone-img-container">
													<img
													className="d-block w-100 zone-img"
													src={global.config.uploadsPath + value.FEATURED_IMAGE}
													/>
												</div>
												<p className="blog-subtitle">{value.SUBTITLE}</p>
												<p className="blog-title">{value.TITLE}</p>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
						<div className="mt-4 mb-4 text-center hide-desktop">
							<Link to={"/revista"} className="btn-primary">Ver todos</Link>
						</div>
						<div className="mt-4 mb-4 show-desktop">
							<Link to={"/revista"} className="btn-primary">Ver todos</Link>
						</div>
					</div>
					<div className="add-home section hide-desktop">
						<img
						className="d-block w-100 add-home-img"
						src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
						alt="First slide"
						/>
						<div className="add-home-container">
							<h3>Saiba quanto poderia ganhar ao <span className="highlight-title">hospedar</span> o seu espaço</h3>
							<p>Coloque o seu espaço disponível para todos que procuram um lar</p>
							<button className="btn-white">Adicionar um lar</button>
						</div>
					</div>
				</div>
				<div className="add-home show-desktop mt-60 mb-60">
					<div className="add-home-container">
						<img
						className="d-block w-100 add-home-img"
						src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
						alt="First slide"
						/>
						<div className="add-home-text">
							<Row>
								<Col md={4} className="col-triangle1">
									<div className="triangle1"></div>
								</Col>
								<Col md={4} className="col-text">
									<div>
										<h3>Saiba quanto poderia ganhar ao <span className="highlight-title">hospedar</span> o seu espaço</h3>
										<p>Transforme o seu espaço extra em renda extra.</p>
										<br/>
										<button className="btn-secondary">Saber mais</button>
									</div>
								</Col>
								<Col md={4} className="col-triangle2">
									<div className="triangle2"></div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
				{/*<div className="container">
					<div className="services section">
						<h3 className="title-default">Serviços na <span className='highlight-title'>3ª pessoa</span></h3>
						<p className="subtitle-default">Encontre os melhores serviços para proporcionar o maior conforto ao seu ente mais querido.</p>
						<div className="services-scroll">
							<div className="services-container">
								{services.map((value, index) => {
									return (
										<Link to={"/service/"+value.URL}>
											<div className="service-item">
												<img
												className="d-block w-100 service-img"
												src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
												alt="First slide"
												/>
												<p className="service-name">{value.NAME}</p>
											</div>
										</Link>
										
									)
								})}
							</div>
						</div>
						<a href="#" className="btn-view-more">Ver todos <FontAwesomeIcon icon={Icons.faArrowRight} /></a>
					</div>
					<div className="extra section">
						<h3 className="title-default"><span className='highlight-title'>A casa onde</span> o seu pai, avós ou bisavós irão morar é importante!</h3>
						<p className="subtitle-default">Procure as melhores condições para aqueles de quem gostamos mais, de modo que possam facilmente encontrar as condições necessárias para continuar a sorrir, cheio de conforto que tanto merecem.<br/><br/><b>Bem vindo a casa!</b></p>
					</div>
				</div>*/}
				<div className="section-margin hscroll-container show-desktop">
					<Container>
					{this.state.slides.map((value, index) => {
						return (
							<div className="hscroll-container2">
							{value.IMAGE_POSITION == 2 && value.SLIDE_WIDTH == 1 && value.TEXT != '' &&
								<div className="hscroll-3">
								<div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
								<img src={global.config.uploadsPath2+value.IMAGE} />
								</div>
							}
							{value.IMAGE_POSITION == 2 && value.SLIDE_WIDTH == 2 && value.TEXT != '' &&
								<div className="hscroll-1">
								<div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
								<img src={global.config.uploadsPath2+value.IMAGE} />
								</div>
							}
							{value.IMAGE_POSITION == 1 && value.SLIDE_WIDTH == 1 && value.TEXT != '' &&
								<div className="hscroll-3">
								<img src={global.config.uploadsPath2+value.IMAGE} />
								<div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
								</div>
							}
							{value.IMAGE_POSITION == 1 && value.SLIDE_WIDTH == 2 && value.TEXT != '' &&
								<div className="hscroll-1">
								<div dangerouslySetInnerHTML={{__html: value.TEXT}}></div>
								<img src={global.config.uploadsPath2+value.IMAGE} />
								</div>
							}
							{value.SLIDE_WIDTH == 1 && value.TEXT == '' &&
								<div className="hscroll-2">
								<img src={global.config.uploadsPath2+value.IMAGE} />
								</div>
							}
							</div>
						)
					})}
					</Container>
				</div>
				<div className="section-margin hscroll-fake show-desktop">
					<button className="hscroll-arrow-left" onClick={() => this.hscrollLeft()}><img src={arrowNav}/></button>
					<button className="hscroll-arrow-right" onClick={() => this.hscrollRight()}><img src={arrowNav}/></button>
				</div>
				<Container>
					<hr className='mt-100 show-desktop' />
					<hr className='hide-desktop' />
					<Row className="mt-60 mb-60">
						<Col md={9}>
						<p className='notes'>
						<b>Notas</b> <br /> 
						<div dangerouslySetInnerHTML={{ __html: this.state.notes.NOTES }} />
						</p>
						</Col>
					</Row>
				</Container>
				<Footer />
			</div>
		)
	}
}

export default Home;

