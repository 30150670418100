import React from 'react';
import { useState, useEffect } from 'react';
import arrow from '../Img/arrow-icon.svg';
import './footer.css';
import {Link} from "react-router-dom";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import logoIcon from '../Img/larin-icon.svg';


class Footer extends React.Component {
	constructor(props){
		super(props);
			this.state = {
			   notes: '',
			}
		}
		componentDidMount(){
  const options = [
    { value: 'pt', label: 'Português' },
    { value: 'en', label: 'English' },
    { value: 'en', label: 'Español' }
  ]


	
}
render () {
  return (
    <div>
    <div className="footer section hide-desktop">
					<Accordion defaultActiveKey="0">
					    <Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(1)} variant="link" eventKey="0">
									Larin
									<div className={'arrow-div arrow-up'} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<ul>
										{/*
										<li>Sobre o Larin</li>
										<li>Testemunhos</li>
										<li>Dicas</li>
										*/}
										<li><Link to={"/revista"}>Revista</Link></li>
										{/*<li>Centro de imprensa</li>
										<li>Contacte-nos</li>*/}
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						{/*
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(2)} variant="link" eventKey="1">
									Precisa de ajuda
									<div className={'arrow-div ' + arrowRotation2} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body>
									<ul>
										<li>Sobre o Larin</li>
										<li>Testemunhos</li>
										<li>Dicas</li>
										<li>Blog</li>
										<li>Centro de imprensa</li>
										<li>Contacte-nos</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(3)} variant="link" eventKey="2">
									Adicionar o seu espaço
									<div className={'arrow-div ' + arrowRotation3} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="2">
								<Card.Body>
									<ul>
										<li>Porque aderir ao Larin</li>
										<li>Hospitalidade</li>
										<li>Perguntas frequentes</li>
										<li>Publicidade</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						*/}
					</Accordion>
					<p className="footer-links">
						<Link to={"/pagina/termos-e-condicoes"}>Termos e condições</Link> • <Link to={"/pagina/politica-privacidade"}>Privacidade</Link>
					</p>
					<img src={logoIcon} className="logo-icon" />
				</div>
				<div className="footer section show-desktop">
					<div className="container">
						{/*<Row>
							<Col md={3}>
								<Select 
								defaultValue={options[0]}
								options={options} 
								/>
							</Col>
							<Col md={3}>
								<h3>Larin</h3>
								<ul>
									
									<li>Sobre o Larin</li>
									<li>Testemunhos</li>
									<li>Dicas</li>
									<li><Link to={"/revista"}>Revista</Link></li>
									<li>Centro de imprensa</li>
									<li>Contacte-nos</li>
								</ul>
							</Col>
							<Col md={3}>
								
								<h3>Precisa de ajuda</h3>
								<ul>
									<li>Sobre o Larin</li>
									<li>Testemunhos</li>
									<li>Dicas</li>
									<li>Blog</li>
									<li>Centro de imprensa</li>
									<li>Contacte-nos</li>
								</ul>
							
							</Col>
							<Col md={3}>
							
								<h3>Adicionar o seu espaço</h3>
								<ul>
									<li>Porque aderir ao Larin</li>
									<li>Hospitalidade</li>
									<li>Perguntas frequentes</li>
									<li>Publicidade</li>
								</ul>
							
							</Col>
						</Row>*/}
						<div className="footer-bottom">
							<div className="footer-bottom-left">
								<img src={logoIcon} className="logo-icon" /> © Larin
							</div>
							<div className="footer-bottom-right">
								<p className="footer-links">
									<Link to={"/pagina/termos-e-condicoes"}>Termos e condições</Link> • <Link to={"/pagina/politica-privacidade"}>Privacidade</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
        </div>
  );
}
}

export default Footer;