import React from 'react';
import ReactDOM from 'react-dom';
import './Blog.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import logoIcon from '../Img/larin-icon.svg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import HouseSingle from '../Main/HouseSingle';
import Menu from '../Main/Menu';
import Header2 from '../Header/Header2';
import axios from "axios";
import ReactGA from "react-ga4";
import {
	Link
  } from "react-router-dom";

class Blog extends React.Component {
	constructor(props){
	super(props);
		this.state = {
		   arrowRotation1: 'arrow-up',
		   arrowRotation2: 'arrow-down',
		   arrowRotation3: 'arrow-down',
		   sticky: false,
		   tabIndex: 0,
		   blogs: [],
		   visibleBlogs: [],
		   firstBlog: [],
		   districts: [],
		}
		this.handleScroll = this.handleScroll.bind(this);
		this.rotateArrow = this.rotateArrow.bind(this);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll, true);
	}

	expandBlogs = currentLength => {
		this.setState({ visibleBlogs: this.state.blogs.slice(1,currentLength+11) });
	};
	
	handleScroll = () => {
		let lastScrollY = 0;
		let ticking = false;
		var myElement = document.getElementsByClassName('main');
		lastScrollY = myElement[0].scrollTop;
		if (!ticking) {
			window.requestAnimationFrame(() => {
				if(lastScrollY > 1){
					this.setState({
						sticky: true
					});
				}
				else{
					this.setState({
						sticky: false
					});
				}
				ticking = false;
			});
			ticking = true;
		}
		
	  };
	  
	
	rotateArrow(i){
		if(i==1){
			if(this.state.arrowRotation1 == 'arrow-down'){
				this.setState({
				  arrowRotation1: 'arrow-up',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
		if(i==2){
			if(this.state.arrowRotation2 == 'arrow-down'){
				this.setState({
				   arrowRotation1: 'arrow-down',
				   arrowRotation2: 'arrow-up',
				   arrowRotation3: 'arrow-down'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
		if(i==3){
			if(this.state.arrowRotation3 == 'arrow-down'){
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-up'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
	}
	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll, true);
		ReactGA.send({ hitType: "pageview", page: window.location.pathname });
		// START: GET BLOG
		axios.get(global.config.apiUrl+"getBlog")
		.then(res => {
			const blogs = res.data;
			this.setState({ blogs });
			const visibleBlogs = res.data.slice(1, 11);
			this.setState({ visibleBlogs });
		  })
		// END: GET BLOG
		// START: GET FIRST BLOG
		axios.get(global.config.apiUrl+"getFirstBlog")
		.then(res => {
			const firstBlog = res.data;
			this.setState({ firstBlog });
		  })
		// END: GET FIRST BLOG
		// START: GET DISTRICTS WITH SPACES
		axios.get(global.config.apiUrl+"getDistrictsWithSpaces")
		.then(res => {
			const districts = res.data;
			this.setState({ districts });
		  })
		// END: GET DISTRICTS WITH SPACES
	}
	render () {
		const options = [
		  { value: 'pt', label: 'Português' },
		  { value: 'en', label: 'English' },
		  { value: 'en', label: 'Español' }
		]
		const { arrowRotation1, arrowRotation2, arrowRotation3, sticky, tabIndex } = this.state;
		return(
			<div className="blog main">
				<div className={sticky ? 'blog-top sticky hide-desktop' : 'blog-top hide-desktop'}>
					<Button className="btn-back"><Link to={"/menu"}><i className="menu-icon icon-arrow-left-icon"></i></Link></Button>
					<div className="search-container">
						<input type="text" className="search" placeholder="O que pretende..."/>
						<div className="search-icon">
							<i className="icon-search-icon"></i>
						</div>
					</div>
					<a href="#" className="btn-secondary">+ Filtros</a>
				</div>
				<Header2 />
				<div className="container">
					<Tabs selectedIndex={tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
					<div className="tab-scroll">
						<TabList>
							<Tab>Lares e residênciais</Tab>
							<Tab>Boas práticas</Tab>
							<Tab>Recomendados</Tab>
						</TabList>
					</div>
						<TabPanel>
							<div className="blog-posts section">
								{this.state.firstBlog[0] &&
									<div className="blog-featured-post">
										<div className="hide-desktop">
											<img
											className="d-block blog-featured-img"
											src={global.config.uploadsPath + this.state.firstBlog[0].FEATURED_IMAGE}
											alt="First slide"
											/>
											<p className="blog-featured-date">23 de Março</p>
											<p className="blog-featured-subtitle">{this.state.firstBlog[0].SUBTITLE}</p>
											<p className="blog-featured-title">{this.state.firstBlog[0].TITLE}</p>
											{this.state.firstBlog[0].NAME && this.state.firstBlog[0].NAME != '' &&
												<p className="blog-featured-author">By <b>{this.state.firstBlog[0].NAME}</b></p>
											}
											<Link className="text-link" to={"/revistasingle/"+this.state.firstBlog[0].BLOG_ID+"/"+encodeURI(this.state.firstBlog[0].TITLE.replace(/ /g,"-"))}>Ler Mais</Link>
										</div>
										<div className="show-desktop">
											<Row>
												<Col xs={6}>
													<p className="blog-featured-subtitle">{this.state.firstBlog[0].SUBTITLE}</p>
													<p className="blog-featured-title">{this.state.firstBlog[0].TITLE}</p>
													{this.state.blogs.DESCRIPTION && this.state.blogs.DESCRIPTION != '' &&
														<div className="blog-featured-desc" dangerouslySetInnerHTML={{__html: this.state.blogs.DESCRIPTION}}></div>
													}
													<Link className="text-link" to={"/revistasingle/"+this.state.firstBlog[0].BLOG_ID+"/"+encodeURI(this.state.firstBlog[0].TITLE.replace(/ /g,"-"))}>Ler Mais</Link>
												</Col>
												<Col xs={6}>
													<img
													className="d-block blog-featured-img"
													src={global.config.uploadsPath + this.state.firstBlog[0].FEATURED_IMAGE}
													alt="First slide"
													/>
													<div className='bg-white-transform'></div>
												</Col>
											</Row>
										</div>
									</div>
								}
								<div className="hide-desktop">
									<Row>
										{this.state.visibleBlogs.map((value, index) => {
											return (
												<Col xs={6} className="blog-col">
													<Link to={"/revistasingle/"+value.BLOG_ID+"/"+encodeURI(value.TITLE.replace(/ /g,"-"))}>
														<img
														className="d-block w-100 blog-img"
														src={global.config.uploadsPath + value.FEATURED_IMAGE}
														alt="First slide"
														/>
														<p className="blog-date">{value.DATE}</p>
														<p className="blog-subtitle">{value.SUBTITLE}</p>
														<p className="blog-title">{value.TITLE}</p>
														{value.NAME && value.NAME != '' &&
															<p className="blog-author">By <b>{value.NAME}</b></p>
														}
													</Link>
												</Col>
											)
										})}
									</Row>
									<div className="btn-div">
										{this.state.visibleBlogs && this.state.visibleBlogs.length < (this.state.blogs.length-1) &&
											<a onClick={() => this.expandBlogs(this.state.visibleBlogs.length)} className="btn-secondary">Carregar mais</a>
										}
									</div>
								</div>
								<div className="show-desktop">
									<Row>
										<Col xs={8}>
											<Row>
												{this.state.visibleBlogs.map((value, index) => {
													return (
														<Col xs={6} className="blog-col">
															<Link to={"/revistasingle/"+value.BLOG_ID+"/"+encodeURI(value.TITLE.replace(/ /g,"-"))}>
																<img
																className="d-block w-100 blog-img"
																src={global.config.uploadsPath + value.FEATURED_IMAGE}
																alt="First slide"
																/>
																<p className="blog-date">{value.DATE}</p>
																<p className="blog-subtitle">{value.SUBTITLE}</p>
																<p className="blog-title">{value.TITLE}</p>
																{value.NAME && value.NAME != '' &&
																	<p className="blog-author">By <b>{value.NAME}</b></p>
																}
															</Link>
														</Col>
													)
												})}
											</Row>
											<div className="btn-div">
												{this.state.visibleBlogs && this.state.visibleBlogs.length < (this.state.blogs.length-1) &&
													<a onClick={() => this.expandBlogs(this.state.visibleBlogs.length)} className="btn-secondary">Carregar mais</a>
												}
											</div>
										</Col>
										<Col xs={4}>
											<div>
												<div className="blog-popular">
													{this.state.blogs.slice(0,4).map((value, index) => {
														return (
															<div className="blog-popular-item">
																<p className="blog-subtitle">{value.SUBTITLE}</p>
																<p className="blog-title">{value.TITLE}</p>
															</div>
														)
													})}
												</div>
												<div className="blog-zones">
													<h3 className="title-default">Explore por <span className='highlight-title'>zonas</span></h3>
													<p className="subtitle-default">Uma nova seleção de casas com conforto e qualidade verificados</p>
													{this.state.districts.map((value, index) => {
														return (
															<div className="zone-item">
																<Link to={"/explore/"+value.DISTRICT_ID+"/"+value.PREFIX+"/"+value.NAME+"/0"}>
																	<div className="zone-img-container">
																		<img
																		className="d-block w-100 zone-img"
																		src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
																		alt="First slide"
																		/>
																	</div>
																	<div className="zone-district-desc">
																		<p className="zone-district">Distrito {value.NAME}</p>
																		<p className="zone-desc">Veja a oferta no distrito {value.PREFIX} {value.NAME}</p>
																	</div>
																</Link>
															</div>
														)
													})}
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</div>
							
							<div className="add-home section hide-desktop">
								<img
								className="d-block w-100 add-home-img"
								src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
								alt="First slide"
								/>
								<div className="add-home-container">
									<h3>Precisa de sair da rotina para precisa de especial assitência?</h3>
									<p>Desfruta de passeios refrescantes com todo o cuidado e gentileza de que merece!</p>
									<button className="btn-white">Ver mais</button>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<h2>Boas práticas</h2>
						</TabPanel>
						<TabPanel>
							<h2>Recomendados</h2>
						</TabPanel>
					</Tabs>
				</div>
				<div className="add-home show-desktop mt-60 mb-60">
					<div className="add-home-container">
						<img
						className="d-block w-100 add-home-img"
						src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
						alt="First slide"
						/>
						<div className="add-home-text">
							<Row>
								<Col md={4} className="col-triangle1">
									<div className="triangle1"></div>
								</Col>
								<Col md={4} className="col-text">
									<div>
										<h3>Saiba quanto poderia ganhar ao <span className="highlight-title">hospedar</span> o seu espaço</h3>
										<p>Transforme o seu espaço extra em renda extra.</p>
										<br/>
										<button className="btn-secondary">Saber mais</button>
									</div>
								</Col>
								<Col md={4} className="col-triangle2">
									<div className="triangle2"></div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
				<div className="footer section hide-desktop">
					{/*
					<Select 
					defaultValue={options[0]}
					options={options} 
					/>*/}
					<Accordion defaultActiveKey="0">
					    <Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(1)} variant="link" eventKey="0">
									Larin
									<div className={'arrow-div ' + arrowRotation1} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<ul>
										{/*
										<li>Sobre o Larin</li>
										<li>Testemunhos</li>
										<li>Dicas</li>
										*/}
										<li><Link to={"/revista"}>Revista</Link></li>
										{/*<li>Centro de imprensa</li>
										<li>Contacte-nos</li>*/}
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						{/*
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(2)} variant="link" eventKey="1">
									Precisa de ajuda
									<div className={'arrow-div ' + arrowRotation2} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body>
									<ul>
										<li>Sobre o Larin</li>
										<li>Testemunhos</li>
										<li>Dicas</li>
										<li>Blog</li>
										<li>Centro de imprensa</li>
										<li>Contacte-nos</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(3)} variant="link" eventKey="2">
									Adicionar o seu espaço
									<div className={'arrow-div ' + arrowRotation3} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="2">
								<Card.Body>
									<ul>
										<li>Porque aderir ao Larin</li>
										<li>Hospitalidade</li>
										<li>Perguntas frequentes</li>
										<li>Publicidade</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						*/}
					</Accordion>
					<p className="footer-links">
						<Link to={"/pagina/termos-e-condicoes"}>Termos e condições</Link> • <Link to={"/pagina/politica-privacidade"}>Privacidade</Link>
					</p>
					<img src={logoIcon} className="logo-icon" />
				</div>
				<div className="footer section show-desktop">
					<div className="container">
						<Row>
							<Col md={3}>
								<Select 
								defaultValue={options[0]}
								options={options} 
								/>
							</Col>
							<Col md={3}>
								<h3>Larin</h3>
								<ul>
									{/*
									<li>Sobre o Larin</li>
									<li>Testemunhos</li>
									<li>Dicas</li>*/}
									<li><Link to={"/revista"}>Revista</Link></li>
									{/*
									<li>Centro de imprensa</li>
									<li>Contacte-nos</li>*/}
								</ul>
							</Col>
							<Col md={3}>
								{/*
								<h3>Precisa de ajuda</h3>
								<ul>
									<li>Sobre o Larin</li>
									<li>Testemunhos</li>
									<li>Dicas</li>
									<li>Blog</li>
									<li>Centro de imprensa</li>
									<li>Contacte-nos</li>
								</ul>
								*/}
							</Col>
							<Col md={3}>
								{/*
								<h3>Adicionar o seu espaço</h3>
								<ul>
									<li>Porque aderir ao Larin</li>
									<li>Hospitalidade</li>
									<li>Perguntas frequentes</li>
									<li>Publicidade</li>
								</ul>
								*/}
							</Col>
						</Row>
						<div className="footer-bottom">
							<div className="footer-bottom-left">
								<img src={logoIcon} className="logo-icon" /> © Larin
							</div>
							<div className="footer-bottom-right">
								<p className="footer-links">
									<Link to={"/pagina/termos-e-condicoes"}>Termos e condições</Link> • <Link to={"/pagina/politica-privacidade"}>Privacidade</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Blog;
