import React from 'react';
import './Legal.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import logoIcon from '../Img/larin-icon.svg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import Menu from './Menu';
import Header from '../Header/Header';
import axios from "axios";
import ReactGA from "react-ga4";
import {
	Link
  } from "react-router-dom";

class Legal extends React.Component {
	constructor(props){
	super(props);
		this.state = {
		   arrowRotation1: 'arrow-up',
		   arrowRotation2: 'arrow-down',
		   arrowRotation3: 'arrow-down',
		   blogID: props.blogID,
		   tabIndex: 0,
		   blogs: [],
		}
		this.rotateArrow = this.rotateArrow.bind(this);
	}

	render () {
		const options = [
		  { value: 'pt', label: 'Português' },
		  { value: 'en', label: 'English' },
		  { value: 'en', label: 'Español' }
		]
		
		return(
			<div className="legal">
				
			</div>
		)
	}
}

export default Legal;
