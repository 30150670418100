import React from 'react';
import ReactDOM from 'react-dom';
import './BlogSingle.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import logoIcon from '../Img/larin-icon.svg';
import facebookIcon from '../Img/facebook-icon.png';
import instagramIcon from '../Img/instagram-icon.png';
import linkedinIcon from '../Img/linkedin-icon.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import Menu from '../Main/Menu';
import Header from '../Header/Header';
import axios from "axios";
import ReactGA from "react-ga4";
import {
	Link
  } from "react-router-dom";
import { Helmet } from 'react-helmet';

class BlogSingle extends React.Component {
	constructor(props){
	super(props);
		this.state = {
		   arrowRotation1: 'arrow-up',
		   arrowRotation2: 'arrow-down',
		   arrowRotation3: 'arrow-down',
		   blogID: props.blogID,
		   tabIndex: 0,
		   blogs: [],
		}
		this.rotateArrow = this.rotateArrow.bind(this);
	}

	componentWillUnmount() {
		//window.removeEventListener('scroll', this.handleScroll, true);
	}

	expandSpaces = currentLength => {
		this.setState({ visibleSpaces: this.state.spaces.slice(0,currentLength+10) });
	};
	
	expandServices = currentLength => {
		this.setState({ visibleServices: this.state.services.slice(0,currentLength+10) });
	};

	shareFacebook = () => {
		const url = encodeURIComponent(window.location.href);
		const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
		window.open(facebookLink, '_blank');
	};
	shareInstagram = () => {

	};
	shareLinkedIn = () => {
		const url = encodeURIComponent(window.location.href);
		const linkedInLink = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
		window.open(linkedInLink, '_blank');
	};
	
	rotateArrow(i){
		if(i==1){
			if(this.state.arrowRotation1 == 'arrow-down'){
				this.setState({
				  arrowRotation1: 'arrow-up',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
		if(i==2){
			if(this.state.arrowRotation2 == 'arrow-down'){
				this.setState({
				   arrowRotation1: 'arrow-down',
				   arrowRotation2: 'arrow-up',
				   arrowRotation3: 'arrow-down'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
		if(i==3){
			if(this.state.arrowRotation3 == 'arrow-down'){
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-up'
				});
			}
			else{
				this.setState({
				  arrowRotation1: 'arrow-down',
				  arrowRotation2: 'arrow-down',
				  arrowRotation3: 'arrow-down'
				});
			}
		}
	}
	componentDidMount(){
		//window.addEventListener('scroll', this.handleScroll, true);
		ReactGA.send({ hitType: "pageview", page: window.location.pathname });
		// START: GET BLOG
		axios.get(global.config.apiUrl+"getBlogByID/"+this.state.blogID)
		.then(res => {
			const blogs = res.data;
			this.setState({ blogs });
		  })
		// END: GET BLOG 2
	}
	render () {
		const options = [
		  { value: 'pt', label: 'Português' },
		  { value: 'en', label: 'English' },
		  { value: 'en', label: 'Español' }
		]
		const { arrowRotation1, arrowRotation2, arrowRotation3, sticky, tabIndex } = this.state;
		return(
			<div className="blog blog-s main">
				<Helmet>
					<meta property="og:type" content="website" />
					<meta property="og:title" content={this.state.blogs.TITLE} />
					<meta property="og:image" content={this.state.blogs.FEATURED_IMAGE} />
				</Helmet>
				<div className="blog-single-top hide-desktop">
					<Button className="btn-back"><Link to={"/revista"}><i className="menu-icon icon-arrow-left-icon"></i></Link></Button>
					<div className="blog-single-top-right">
						<Button className="btn-back"><i className="menu-icon icon-share-icon"></i></Button>
						<Button className="btn-back"><i className="menu-icon icon-heart-icon"></i></Button>
					</div>
				</div>
				<div className='show-desktop'>
					<Header />
				</div>
				<div className="blog-single section">
					{this.state.blogs &&
						<div className="blog-featured-post">
							<img
							className="d-block blog-featured-img"
							src={global.config.uploadsPath + this.state.blogs.FEATURED_IMAGE}
							alt="First slide"
							/>
							<div className='bg-white-transform show-desktop'></div>
							<div className="container">
								<p className="blog-featured-date">{this.state.blogs.DATE}</p>
								<Row className="blog-s-content">
									<Col md={4}>
										<p className="blog-featured-subtitle">{this.state.blogs.SUBTITLE}</p>
										<p className="blog-featured-title">{this.state.blogs.TITLE}</p>
										<div className="show-desktop">
											<div className="blog-share-div">
												<p>Partilhar com um amigo</p>
												<div>
													<button onClick={this.shareFacebook} className="btn-share-socials"><img src={facebookIcon} className="socials-icon" /></button>
													<button onClick={this.shareLinkedin} className="btn-share-socials"><img src={linkedinIcon} className="socials-icon" /></button>
												</div>
											</div>
										</div>
									</Col>
									<Col md={8}>
										<div className="blog-featured-desc" dangerouslySetInnerHTML={{__html: this.state.blogs.DESCRIPTION}}>
										</div>
										{this.state.blogs.NAME && this.state.blogs.NAME != '' &&
											<div className="blog-featured-author">
												<div className="blog-featured-author-icon">{this.state.blogs.NAME.split(' ').map(name => name[0].toUpperCase()).join('')}</div>
												<p>By <b>{this.state.blogs.NAME}</b></p>
											</div>
										}
									</Col>
								</Row>
							</div>
						</div>
					}
					<div className="container">
						<Row>
							<Col md={4}></Col>
							<Col md={8}>
								<h3 style={{color: "#483A3A", fontSize: 14, fontWeight: "bold", marginBottom: 20}}>Outros artigos</h3>
								<div className="blog-other-posts">
									<div className="blog-other-post-1">
										<i className="menu-icon icon-arrow-left-icon"></i>
										<p>Passeios ao final de tarde em jardins e parques naturais</p>
									</div>
									<div className="blog-other-post-2">
										<p>Passeios ao final de tarde em jardins e parques naturais</p>
										<i className="menu-icon icon-arrow-right-icon"></i>
									</div>
								</div>
								<br/>
								{/*
								<hr/>
								<h3 style={{color: "#483A3A", fontSize: 14, fontWeight: "bold", marginBottom: 20}}>Deixe um comentário</h3>
								<textarea className="comment-new-message" rows='1'>Mensagem</textarea>
								<div className="btn-div-2">
									<a className="btn-secondary">Enviar Comentário</a>
								</div>
								<div className="blog-comments">
									<div className="blog-comment">
										<img
										className="d-block blog-comment-img"
										src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
										alt="First slide"
										/>
										<div className="blog-comment-right">
											<a className="blog-comment-name">António Castro Silva</a>
											<p className="blog-comment-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											<div className="btn-div-comment">
												<a>Gosto</a>
												<a>Responder</a>
												<a>Marcar como spam</a>
											</div>
										</div>
									</div>
									<div className="blog-comment">
										<img
										className="d-block blog-comment-img"
										src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
										alt="First slide"
										/>
										<div className="blog-comment-right">
											<a className="blog-comment-name">António Castro Silva</a>
											<p className="blog-comment-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											<div className="btn-div-comment">
												<a>Gosto</a>
												<a>Responder</a>
												<a>Marcar como spam</a>
											</div>
										</div>
									</div>
									<div className="blog-comment">
										<img
										className="d-block blog-comment-img"
										src={process.env.PUBLIC_URL + '/Slides/slide-1.jpg'}
										alt="First slide"
										/>
										<div className="blog-comment-right">
											<a className="blog-comment-name">António Castro Silva</a>
											<p className="blog-comment-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
											<div className="btn-div-comment">
												<a>Gosto</a>
												<a>Responder</a>
												<a>Marcar como spam</a>
											</div>
										</div>
									</div>
									<div className="btn-div">
										<a className="btn-secondary">Carregar mais</a>
									</div>
									<div className="blog-share-div hide-desktop">
										<p>Partilhar com um amigo</p>
										<div>
											<i className="menu-icon icon-facebook-icon"></i>
											<i className="menu-icon icon-facebook-icon"></i>
											<i className="menu-icon icon-facebook-icon"></i>
										</div>
									</div>
								</div>
								*/}
							</Col>
						</Row>
					</div>
				</div>
				<div className="footer hide-desktop">
					{/*
					<Select 
					defaultValue={options[0]}
					options={options} 
					/>*/}
					<Accordion defaultActiveKey="0">
					    <Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(1)} variant="link" eventKey="0">
									Larin
									<div className={'arrow-div ' + arrowRotation1} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<ul>
										{/*
										<li>Sobre o Larin</li>
										<li>Testemunhos</li>
										<li>Dicas</li>
										*/}
										<li><Link to={"/revista"}>Revista</Link></li>
										{/*<li>Centro de imprensa</li>
										<li>Contacte-nos</li>*/}
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						{/*
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(2)} variant="link" eventKey="1">
									Precisa de ajuda
									<div className={'arrow-div ' + arrowRotation2} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body>
									<ul>
										<li>Sobre o Larin</li>
										<li>Testemunhos</li>
										<li>Dicas</li>
										<li>Blog</li>
										<li>Centro de imprensa</li>
										<li>Contacte-nos</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header>
								<Accordion.Toggle as={Button} onClick={() => this.rotateArrow(3)} variant="link" eventKey="2">
									Adicionar o seu espaço
									<div className={'arrow-div ' + arrowRotation3} >
									<i className="menu-icon icon-arrow-down-icon"></i>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="2">
								<Card.Body>
									<ul>
										<li>Porque aderir ao Larin</li>
										<li>Hospitalidade</li>
										<li>Perguntas frequentes</li>
										<li>Publicidade</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						*/}
					</Accordion>
					<p className="footer-links">
						<Link to={"/pagina/termos-e-condicoes"}>Termos e condições</Link> • <Link to={"/pagina/politica-privacidade"}>Privacidade</Link>
					</p>
					<img src={logoIcon} className="logo-icon" />
				</div>
				<div className="footer show-desktop">
					<div className="container">
						<Row>
							<Col md={3}>
								<Select 
								defaultValue={options[0]}
								options={options} 
								/>
							</Col>
							<Col md={3}>
								<h3>Larin</h3>
								<ul>
									{/*
									<li>Sobre o Larin</li>
									<li>Testemunhos</li>
									<li>Dicas</li>*/}
									<li><Link to={"/revista"}>Revista</Link></li>
									{/*
									<li>Centro de imprensa</li>
									<li>Contacte-nos</li>*/}
								</ul>
							</Col>
							<Col md={3}>
								{/*
								<h3>Precisa de ajuda</h3>
								<ul>
									<li>Sobre o Larin</li>
									<li>Testemunhos</li>
									<li>Dicas</li>
									<li>Blog</li>
									<li>Centro de imprensa</li>
									<li>Contacte-nos</li>
								</ul>
								*/}
							</Col>
							<Col md={3}>
								{/*
								<h3>Adicionar o seu espaço</h3>
								<ul>
									<li>Porque aderir ao Larin</li>
									<li>Hospitalidade</li>
									<li>Perguntas frequentes</li>
									<li>Publicidade</li>
								</ul>
								*/}
							</Col>
						</Row>
						<div className="footer-bottom">
							<div className="footer-bottom-left">
								<img src={logoIcon} className="logo-icon" /> © Larin
							</div>
							<div className="footer-bottom-right">
								<p className="footer-links">
									<Link to={"/pagina/termos-e-condicoes"}>Termos e condições</Link> • <Link to={"/pagina/politica-privacidade"}>Privacidade</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default BlogSingle;
