import React from 'react';
import ReactDOM from 'react-dom';
import './BlogSingle.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import logoIcon from '../Img/larin-icon.svg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import Menu from './Menu';
import Header from '../Header/Header';
import Footer from '../Components/Footer';
import axios from "axios";
import ReactGA from "react-ga4";
import {
	Link
  } from "react-router-dom";

class Pagina extends React.Component {
	constructor(props){
	super(props);
		this.state = {
		   slug: props.slug,
		   blogs: [],
		}
	}

	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll, true);
		ReactGA.send({ hitType: "pageview", page: window.location.pathname });
		// START: GET BLOG
		axios.get(global.config.apiUrl+"getPageByURL/"+this.state.slug)
		.then(res => {
			const blogs = res.data;
			this.setState({ blogs });
			
		  })
		// END: GET BLOG
		
	}
	render () {
		
		return(
			<div className="blog blog-s main">
				<div className="blog-single-top hide-desktop">
					<Button className="btn-back"><Link to={"/revista"}><i className="menu-icon icon-arrow-left-icon"></i></Link></Button>
					<div className="blog-single-top-right">
						<Button className="btn-back"><i className="menu-icon icon-share-icon"></i></Button>
						<Button className="btn-back"><i className="menu-icon icon-heart-icon"></i></Button>
					</div>
				</div>
				<div className='show-desktop'>
					<Header />
				</div>
				<div className="blog-single section">
					{this.state.blogs &&
						<div className="blog-featured-post">
							<div className='bg-white-transform show-desktop'></div>
							<div className="container">
								
								<Row className="blog-s-content">
									<Col md={4}>
										<p className="blog-featured-title">{this.state.blogs.TITLE}</p>
									</Col>
									<Col md={8}>
										<div className="blog-featured-desc" dangerouslySetInnerHTML={{__html: this.state.blogs.DESCRIPTION}}>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					}
				</div>
				<Footer />
			</div>
		)
	}
}

export default Pagina;
